.spiner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 34px;
  height: 34px;

  img {
    height: 100%;
    width: 100%;
  }
}