@import 'scss/settings/vars.scss';

.image {
  width: 100%;
  height: 100%;
  overflow: hidden;

  img {
    transition: all 0.7s;
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;

    @media (min-width: $breakpoint_lg) {
      &:hover {
        height: 102%;
        width: 102%;
      }
    }
  }
}