.video {
  width: 100%;
  height: 100%;

  video {
    width: 100%;
    height: 100%;
    object-position: 50% 50%;
    object-fit: cover;
  }
}