.section-list {
  color: currentColor;
  background: inherit;

  &.reverse {
    @media (min-width: $breakpoint_md) {
      .preview {
        order: 0;
      }

      .section-list__body {
        order: 1;
      }
    }
  }

  &__wrapper {
    padding: 30px 15px;

    @media (min-width: $breakpoint_md) {
      max-width: $max_width_md;
      padding: 30px 0;
      margin: 0 auto;
    }

    @media (min-width: $breakpoint_lg) {
      max-width: $max_width_lg;
      padding: 40px 0;
    }

    @media (min-width: $breakpoint_xl) {
      max-width: $max_width_xl;
      padding: 50px 0;
    }
  }

  &__information {
    display: grid;
    gap: 30px;

    .preview {
      order: 0;
    }

    @media (min-width: $breakpoint_md) {
      max-width: $max_width_md;
      margin: 0 auto;
      grid-template-columns: repeat(2, 1fr);
      gap: 30px;

      .preview {
        order: 1;
      }
    }

    @media (min-width: $breakpoint_lg) {
      max-width: $max_width_lg;
      gap: 40px;
    }

    @media (min-width: $breakpoint_xl) {
      max-width: $max_width_xl;
      gap: 110px;
    }
  }

  &__body {
    order: 1;

    @media (min-width: $breakpoint_md) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      order: 0;
    }
  }

  &__title {
    font-family: $font_family_title;
    font-size: 30px;
    font-weight: 300;

    @media (min-width: $breakpoint_md) {
      font-size: 36px;
    }

    @media (min-width: $breakpoint_lg) {
      font-size: 42px;
    }

    @media (min-width: $breakpoint_xl) {
      font-size: 54px;
    }
  }

  &__copy {
    margin-top: 10px;

    @media (min-width: $breakpoint_md) {
      font-size: 18px;
    }

    @media (min-width: $breakpoint_lg) {
      font-size: 20px;
    }

    @media (min-width: $breakpoint_xl) {
      margin-top: 20px;
      font-size: 22px;
    }

    &:first-of-type {
      margin-top: 20px;

      @media (min-width: $breakpoint_xl) {
        margin-top: 30px;
      }
    }
  }

  &__link {
    margin-top: 10px;

    @media (min-width: $breakpoint_md) {
      font-size: 16px;
    }

    @media (min-width: $breakpoint_lg) {
      font-size: 18px;
    }

    @media (min-width: $breakpoint_xl) {
      margin-top: 20px;
      font-size: 20px;
    }

    &:first-of-type {
      margin-top: 20px;

      @media (min-width: $breakpoint_xl) {
        margin-top: 30px;
      }
    }
  }

  &__list {
    margin-top: 20px;
    display: grid;
    gap: 10px;

    @media (min-width: $breakpoint_md) {
      margin-top: 24px;
      font-size: 16px;
      grid-template-columns: repeat(2, 1fr);
      gap: 15px;
    }

    @media (min-width: $breakpoint_lg) {
      margin-top: 34px;
      font-size: 18px;
      grid-template-columns: repeat(3, 1fr);
      row-gap: 15px;
      column-gap: 30px;
    }

    @media (min-width: $breakpoint_xl) {
      margin-top: 30px;
      font-size: 20px;
    }
  }

  &__item {
    display: grid;
    grid-template-columns: 24px auto;
    align-items: center;
    gap: 10px;

    @media (min-width: $breakpoint_xl) {
      margin-top: 20px;
    }

    .icon {
      display: inline-block;
      height: 24px;
      width: 24px;
      // color:  rgb(129, 0, 171);
      // color:  rgb(31, 172, 247);

      svg {
        height: 100%;
        width: 100%;
      }
    }
  }
}