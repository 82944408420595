// fonts:
$font_family_main: 'Open Sans', sans-serif;
$font_family_title: 'Roboto Condensed', sans-serif;

// breakpoints:
$breakpoint_sm: 400px;
$breakpoint_md: 770px;
$breakpoint_lg: 1150px;
$breakpoint_xl: 1440px;

// max-widths:
$max_width_md: 690px;
$max_width_lg: 930px;
$max_width_xl: 1140px;

// hegths:
$heigth_header_xs: 50px;
$heigth_header_lg: 70px;

// colors:
$color_error: #ff0000;
$color_success: #08fc3d;

// bg:
// $bg_dark: #212227;
$bg_dark: rgb(37, 36, 36);
$bg_light: #fff;