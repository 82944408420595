.accordeon {
  margin-top: 20px;
  display: grid;
  gap: 20px;

  @media (min-width: $breakpoint_md) {
    margin-top: 24px;
  }

  @media (min-width: $breakpoint_lg) {
    margin-top: 34px;
  }

  &__item {
    border: 1px solid #777;
    border-radius: 5px;
  }

  &__btn {
    width: 100%;
    text-align: left;
    overflow: hidden;
  }

  &__header {
    padding: 10px;
    display: grid;
    gap: 15px;
    grid-template-columns: auto 12px;
    border-radius: 5px;

    @media (min-width: $breakpoint_md) {
      grid-template-columns: auto 14px;
    }

    @media (min-width: $breakpoint_lg) {
      padding: 15px;
      align-items: center;
      grid-template-columns: auto 16px;
    }

    @media (min-width: $breakpoint_xl) {
      padding: 20px;
      grid-template-columns: auto 18px;
    }
  }

  &__icon {
    margin-top: 4px;
    display: flex;
    height: 12px;
    width: 12px;

    @media (min-width: $breakpoint_md) {
      height: 14px;
      width: 14px;
    }

    @media (min-width: $breakpoint_lg) {
      height: 16px;
      width: 16px;
    }

    @media (min-width: $breakpoint_xl) {
      height: 18px;
      width: 18px;
    }

    &.rotate {
      color: rgba(255, 68, 0, 0.8);

      svg {
        transform: rotate(135deg);
      }
    }

    svg {
      height: 100%;
      width: 100%;
      transition: all 0.3s;
    }
  }

  &__title {
    margin-top: 4px;
    font-size: 18px;

    @media (min-width: $breakpoint_md) {
      font-size: 20px;
    }

    @media (min-width: $breakpoint_lg) {
      font-size: 22px;
    }

    @media (min-width: $breakpoint_xl) {
      font-size: 24px;
    }
  }

  &__body-list {
    position: relative;
    height: 0;
    padding: 2px 10px 10px;
    overflow: hidden;
  }

  &__body-item {
    margin-top: 10px;

    @media (min-width: $breakpoint_lg) {
      font-size: 18px;
    }

    @media (min-width: $breakpoint_xl) {
      margin-top: 15px;
      font-size: 20px;
    }

    p {
      display: grid;
      grid-template-columns: 10px auto;

      @media (min-width: $breakpoint_lg) {
        gap: 5px;
      }
    }
  }
}
