.section-acc {
  color: currentColor;
  background: inherit;

  &__wrapper {
    padding: 30px 15px;

    @media (min-width: $breakpoint_md) {
      max-width: $max_width_md;
      padding: 30px 0;
      margin: 0 auto;
    }

    @media (min-width: $breakpoint_lg) {
      max-width: $max_width_lg;
      padding: 40px 0;
    }

    @media (min-width: $breakpoint_xl) {
      max-width: $max_width_xl;
      padding: 50px 0;
    }
  }

  &__title {
    font-family: $font_family_title;
    font-size: 30px;
    font-weight: 300;

    @media (min-width: $breakpoint_md) {
      font-size: 36px;
      max-width: 65%;
    }

    @media (min-width: $breakpoint_lg) {
      font-size: 42px;
    }

    @media (min-width: $breakpoint_xl) {
      font-size: 54px;
    }
  }
}
