.btn-main {
  position: fixed;
  bottom: 15px;
  left: 50%;
  transform: translateX(-50%);
  height: 50px;
  width: calc(100vw - 30px);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background: rgb(0, 133, 205);
  cursor: pointer;

  @media (min-width: $breakpoint_md) {
    width: 200px;
    bottom: 20px;
    transition: background 0.3s;

    &:hover {
      background: rgb(31, 172, 247);
    }
  }

  svg {
    height: 30px;
    width: 30px;
  }

  span {
    margin-left: 7px;
  }
}