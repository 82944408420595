@import 'scss/settings/vars.scss';

.wrapper {
  padding: 30px 15px;

  @media (min-width: $breakpoint_md) {
    max-width: $max_width_md;
    margin: 0 auto;
    padding: 30px 0;
  }

  @media (min-width: $breakpoint_lg) {
    max-width: $max_width_lg;
    padding: 40px 0;
  }

  @media (min-width: $breakpoint_xl) {
    max-width: $max_width_xl;
    padding: 50px 0;
    gap: 110px;
  }
}

.list {
  display: grid;
  gap: 20px;

  @media (min-width: $breakpoint_md) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: $breakpoint_lg) {
    gap: 30px;
  }
}

.listItem {
  display: block;
  min-height: 5vw;
}