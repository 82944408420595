@import 'scss/settings/vars.scss';

.header {
  height: 70vw;
  width: 100%;

  @media (min-width: $breakpoint_md) {
    height: 280px;
  }

  @media (min-width: $breakpoint_lg) {
    height: 350px;
  }

  @media (min-width: $breakpoint_lg) {
    height: 400px;
  }
}

.title {
  margin-top: 20px;
  font-family: $font_family_title;
  font-size: 30px;
  font-weight: 300;

  @media (min-width: $breakpoint_md) {
    margin-top: 30px;
    font-size: 36px;
  }

  @media (min-width: $breakpoint_lg) {
    margin-top: 40px;
    font-size: 42px;
  }

  @media (min-width: $breakpoint_xl) {
    font-size: 54px;
  }
}

.article {
  margin-top: 15px;

  @media (min-width: $breakpoint_md) {
    margin-top: 20px;
    font-size: 16px;
  }

  @media (min-width: $breakpoint_lg) {
    margin-top: 25px;
    font-size: 18px;
  }

  @media (min-width: $breakpoint_xl) {
    margin-top: 30px;
    font-size: 20px;
  }
}

.read {
  margin-top: 10px;
  margin-right: 10px;
  text-align: right;
  font-size: 16px;
  color: rgb(0, 133, 205);

  @media (min-width: $breakpoint_md) {
    display: none;
  }
}