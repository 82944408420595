.section-base {
  color: currentColor;
  background: inherit;

  &.reverse {
    @media (min-width: $breakpoint_md) {
      .section-base__body {
        order: 1;
      }
    }
  }

  &__wrapper {
    padding: 30px 15px;
    display: grid;
    gap: 30px;

    @media (min-width: $breakpoint_md) {
      max-width: $max_width_md;
      margin: 0 auto;
      padding: 30px 0;
      grid-template-columns: repeat(2, 1fr);
      gap: 30px;
    }

    @media (min-width: $breakpoint_lg) {
      max-width: $max_width_lg;
      padding: 40px 0;
      gap: 40px;
    }

    @media (min-width: $breakpoint_xl) {
      max-width: $max_width_xl;
      padding: 50px 0;
      gap: 110px;
    }
  }

  &__body {
    @media (min-width: $breakpoint_md) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      order: -1;
    }
  }

  &__title {
    font-family: $font_family_title;
    font-size: 30px;
    font-weight: 300;

    @media (min-width: $breakpoint_md) {
      font-size: 36px;
    }

    @media (min-width: $breakpoint_lg) {
      font-size: 42px;
    }

    @media (min-width: $breakpoint_xl) {
      font-size: 54px;
    }
  }

  &__copy,
  &__link {
    margin-top: 10px;

    @media (min-width: $breakpoint_md) {
      font-size: 16px;
    }

    @media (min-width: $breakpoint_lg) {
      font-size: 18px;
    }

    @media (min-width: $breakpoint_xl) {
      margin-top: 20px;
      font-size: 20px;
    }

    &:first-of-type {
      margin-top: 20px;

      @media (min-width: $breakpoint_xl) {
        margin-top: 30px;
      }
    }
  }

  &__link {
    display: block;
    width: max-content;
    color: rgb(0, 133, 205);

    @media (min-width: $breakpoint_lg) {
      transition: transform 0.7s, opacity 0.7s, color 0.3s;

      &:hover {
        color: rgb(31, 172, 247);
      }
    }
  }
}
