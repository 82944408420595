.preloader {
  position: fixed;
  z-index: 10;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  background: $bg_light;

  &.dark {
    background: $bg_dark;
  }
}
